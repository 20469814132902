import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../common/components/runtime-context';
import { AppState } from '../../../common/types';
import {
  getIsSeeAllLinkEnabled,
  getIsWidgetTitleEnabled,
} from '../../selectors/related-posts-selectors';
import PostList from '../post-list';
import SeeAllLink from '../see-all-link/see-all-link';
import styles from './related-posts.scss';

type Props = ReturnType<typeof mapRuntimeToProps>;

const RelatedPosts = ({ isSeeAllLinkEnabled, isWidgetTitleEnabled }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const showHeader = isSeeAllLinkEnabled || isWidgetTitleEnabled;
  const title = isWidgetTitleEnabled ? (
    <h2 className={styles.title}>{t('related-posts.title')}</h2>
  ) : (
    <div />
  );
  const seeAllLink = isSeeAllLinkEnabled ? <SeeAllLink /> : <div />;

  return (
    <div className={isMobile ? styles.mobile : undefined}>
      {showHeader ? (
        <div className={styles.header}>
          {title}
          {seeAllLink}
        </div>
      ) : null}
      <div className={styles.list}>
        <PostList />
      </div>
    </div>
  );
};

const mapRuntimeToProps = (state: AppState) => ({
  isSeeAllLinkEnabled: getIsSeeAllLinkEnabled(state),
  isWidgetTitleEnabled: getIsWidgetTitleEnabled(state),
});

export default connect(mapRuntimeToProps)(RelatedPosts);
